export default {
    nav: {
        main: {
            timeRegistration: 'Tijdsregistratie',
            roadmap: 'Roadmap',
            operations: 'Operatie',
            crm: 'CRM',
            infra: 'Infra',
            assets: 'Stamgegevens',
            iso: 'ISO',
            docs: 'Docs',
        },
        assets: {
            users: 'Gebruikers',
            globalValues: 'Instellingen',
            globalFiles: 'Bijzondere bestanden',
        },
        audit: {
            accessLog: 'Toegangslog',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        crm: {
            entities: 'Entiteiten',
            companies: 'Bedrijven',
            contacts: 'Contacten',
            teams: 'Teams',
            contracts: 'Contracts'
        },
        operations: {
            tickets: 'Tickets',
            tags: 'Tags',
            milestones: 'Milestones',
            milestoneStatuses: 'Milestone statussen',
            project: 'Projecten',
            userSchedule: 'Werkschema'
        },
        timeRegistration: {
            myTime: 'Mijn Tijd',
            overview: 'Overzicht'
        },
        infra: {
            server: 'Servers'
        },
        modules: {
            overview: 'Modules',
            whale: {
                overview: 'Whale'
            }
        },
        iso: {
            checks: 'Checks',
            documents: {
                title: 'Documenten',
                header: 'Documentenregister',
                documents: 'Documenten',
                chapters: 'Hoofdstukken'
            }
        }
    },
    user: {
        overview: {
            title: 'Gebruikers',
            addButton: 'Nieuwe gebruiker',
        },
        create: {
            title: 'Gebruiker aanmaken',
        },
        edit: {
            title: 'Gebruiker aanpassen',
        },
        field: {
            fullName: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            email: { label: 'E-mail' },
            language: { label: 'Taal' },
            password: { label: 'Wachtwoord' },
            dateJoined: { label: 'Toegevoegd Op' },
            abbreviation: { label: 'Afkorting' },
            team: { label: 'Team' },
            extraTimeLastMonth: { label: 'Extra tijd vorige maand' },
            extraTimeThisMonth: { label: 'Extra tijd deze maand' },
            missingTimeLastMonth: { label: 'Missende tijd vorige maand' },
            missingTimeThisMonth: { label: 'Missende tijd deze maand' },
            groups: {
                label: 'Groepen',
                value: {
                    operation_manager: 'Operation manager',
                    team_lead: 'Team lead',
                    employee: 'Werkenemer',
                    superuser: 'Supergebruiker',
                    admin: 'Administrator',
                    manager: 'Manager',
                    experimental: 'Experimenteel',
                    iso: 'ISO',
                    sales: 'Sales'
                },
                empty: 'Geen groepen toegekend',
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not active',
                }
            },
            external: { label: 'Extern' },
            exactId: { label: 'Exact ID' },
            autoClearTimeRegistration: { label: 'Automatisch Tijdregistratie Leegmaken' },
        },
        login: {
            title: 'Inloggen',
            forgotPasswordLink: 'Wachtwoord vergeten?',
            errors: {
                invalidCredentials: 'E-mail of wachtwoord is niet correct',
                unknown: 'Onbekende fout, status code: {{status}}',
            },
            loginButton: 'Inloggen',
        },
        passwordForgot: {
            title: 'Wachtwoord vergeten',
            requestButton: 'Verstuur e-mail',
            requestedEmailText:
                'Er is een e-mail met een wachtwoord reset code verzonden naar uw e-mail adres.',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
        },
        resetPassword: {
            title: 'Wachtwoord reset',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is succesvol gereset',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
        },
        changePassword: {
            title: 'Wachtwoord aanpassen',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Opslaan',
        },
        account: {
            title: 'Profiel',
            logoutButton: 'Uitloggen',
            info: {
                title: 'Gegevens',
            },
            password: {
                title: 'Wachtwoord veranderen',
            },
            logout: {
                title: 'Uitloggen',
                confirm: 'Weet u zeker dat u wilt uitloggen?'
            },
        },
    },
    globalValue: {
        edit: {
            title: '{{key}} aanpassen',
        },
        overview: {
            title: 'Instellingen',
        },
        field: {
            key: {
                label: 'Naam instelling',
            },
            value: {
                label: 'Waarde',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Bijzonder bestand bewerken',
        },
        overview: {
            title: 'Bijzondere bestanden',
        },
        field: {
            key: {
                label: 'Naam voor bestand',
            },
            value: {
                label: 'Bestand',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Auditlog',
        },
        field: {
            originalUser: {
                label: 'Oorspronkelijke gebruiker',
            },
            masqueradingAsUser: {
                label: 'Gemaskeerd als gebruiker',
            },
            ipAddress: {
                label: 'IP-adres',
            },
            method: {
                label: 'HTTP-methode',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Statuscode',
            },
            additionalInfo: {
                label: 'Extra informatie',
            },
            requestTime: {
                label: 'Tijdstip van toegang',
            },
        },
    },
    myFilter: {
        custom: 'Nieuw',
        action: {
            unsetDefault: 'Gebruik niet als standaard',
            setDefault: 'Gebruik als standaard',
            setCurrent: 'Sla huidige filters op',
            delete: 'Verwijderen',
        },
    },
    tooltips: {
        masquerade: 'Voordoen als',
        edit: 'Aanpassen',
        delete: 'Verwijderen',
        view: 'Bekijk',
        restore: 'Herstel',
        history: 'Geschiedenis',
        copy: 'Kopiëren',
    },
    form: {
        search: 'Zoek',
        repeat: 'Herhaal',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Anders',
            none: 'Nog geen bestand geüpload',
        },
        saveButton: 'Opslaan',
        saveAndBackButton: 'Opslaan & Terug',
        saveAndNewButton: 'Opslaan & Nieuw',
        deleteButton: 'Verwijderen',
        multiPick: {
            searchPlaceholder: 'Selecteer…',
            selectedText: '$1 uit $2 geselecteerd',
            noneSelectedText: 'Geen geselecteerd',
            selectAllButton: 'Alle',
            selectNoneButton: 'Geen',
        },
        notifications: {
            saveSuccess: 'Succesvol opgeslagen',
            deleteSuccess: 'Succesvol verwijderd',
            saveError: 'Fout met opslaan ({{status}})',
            saveValError: 'Niet alle informatie is correct ingevuld',
            saveAuthError: 'Niet geauthorizeerd',
            newAppVersion:
                'Nieuwe versie beschikbaar, klik om de pagina te verversen',
        },
        draftJs: {
            style: {
                'header-one': { label: 'H1' },
                'header-two': { label: 'H2' },
                'header-three': { label: 'H3' },
                'header-four': { label: 'H4' },
                'header-five': { label: 'H5' },
                'header-six': { label: 'H6' },
                blockquote: { label: 'Blockquote' },
                'unordered-list-item': { label: 'UL' },
                'ordered-list-item': { label: 'OL' },
                'code-block': { label: 'Code Block' },
                BOLD: { label: 'Bold' },
                ITALIC: { label: 'Italic' },
                UNDERLINE: { label: 'Underline' },
                CODE: { label: 'Monospace' },
            },
        },
        submitButton: 'Verstuur',
        cancelButton: 'Annuleren',
        addButton: 'Toevoegen',
        deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
        restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
        startDate: 'Start Datum',
        endDate: 'Eind Datum',
        smartDateRange: {
            value: {
                today: 'Vandaag',
                yesterday: 'Gisteren',
                past_7_days: 'Laatste 7 Dagen',
                past_30_days: 'Laatste 30 Dagen',
                custom: 'Anders',
                last_month: 'Vorige Maand',
                last_quarter: 'Vorig Kwartaal',
                last_year: 'Vorig Jaar',
                this_month: 'Huidige Maand',
                this_quarter: 'Huidig Kwartaal',
                this_year: 'Huidig Jaar',
                all_time: 'Altijd',
            },
            period: {
                last: 'Vorig',
                this: 'Huidig',
                value: {
                    month: 'Maand',
                    quarter: 'Kwartaal',
                    year: 'Jaar',
                },
            },
        },
    },
    crash: {
        heading: 'Applicatie gecrasht',
        subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
        subHeading2: 'Ons team is op de hoogte gesteld.',
        reload: 'Herlaad pagina'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        month: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'April',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Pagina niet gevonden',
    brandingMessage: 'Powered by Code Yellow',


    milestone: {
        create: {
            title: 'Milestone toevoegen'
        },
        overview: {
            title: 'Milestone',
            addChild: 'Kind toevoegen',
            new: 'NIEUW',
            dragInfo: 'Na het slepen worden milestones standaard als hoofdmilestone neergezet, houd shift ingedrukt om ze in plaats daarvan als kindmilestone neer te zetten.',
        },
        field: {
            id: { label: 'ID' },
            status: {
                label: 'Status',
                options: {
                    not_started: 'Niet gestart',
                    wip: 'In progressie',
                    completed: 'Klaar'
                }
            },
            flagged: {
                label: 'Gevlagd',
                options: {
                    all: 'Beide',
                    yes: 'Gevlagd',
                    no: 'Niet gevlagd',
                }
            },
            onHold: {
                label: 'On hold',
                options: {
                    all: 'Beide',
                    yes: 'On hold',
                    no: 'Niet on hold',
                }
            },
            dueAt: { label: 'Verwacht' },
            name: { label: 'Naam' },
            notes: { label: 'Notities' },
            ticket: { label: 'Ticket' },
            deliveredBy: { label: 'Opgeleverd door' },
            receivedBy: { label: 'Ontvangen door' },
            team: { label: 'Team' },
            project: { label: 'Project' },
            company: { label: 'Bedrijf' },
            createdAt: { label: 'Aangemaakt op' },
            noteThread: { label: 'Notitie thread' },
            ordering: { label: 'Sortering' },
            parent: { label: 'Hoofdmilestone' }
        },
    },
    milestoneStatus: {
        overview: {
            addButton: 'Milestonestatus toevoegen'
        },
        create: {
            title: 'Milestonestatus toevoegen'
        },
        edit: {
            title: 'Milestonestatus aanpassen'
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Naam'
            },
            color: {
                label: 'Kleur'
            },
            default: {
                label: 'Standaard'
            }
        }
    },
    timeRegistration: {
        overview: {
            title: 'Entries',
            stat: {
                total_duration: 'Totale Tijdsduur',
                duration_per_user: 'Tijdsduur per Gebruiker',
                duration_per_project: 'Tijdsduur per Project',
                duration_per_ticket: 'Tijdsduur per Ticket',
            },
            exportButton: 'Exporteren',
        },
        create: {
            title: 'Entry toevoegen',
        },
        edit: {
            title: 'Entry aanpassen',
        },
        myTime: {
            title: 'Mijn Tijd',
            today: 'Vandaag ({{date}})',
            yesterday: 'Gisteren ({{date}})',
            addButton: 'Entry toevoegen',
            addHolidayButton: 'Vakantie toevoegen',
            addHoliday: {
                title: 'Vakantie toevoegen',
            },
            isAltered: 'Deze tijdregistratie is aangepast door de administratie.',
            lastWeekDay: {
                monday: 'Afgelopen maandag ({{date}})',
                tuesday: 'Afgelopen dinsdag ({{date}})',
                wednesday: 'Afgelopen woensdag ({{date}})',
                thursday: 'Afgelopen donderdag ({{date}})',
                friday: 'Afgelopen vrijdag ({{date}})',
                saturday: 'Afgelopen zaterdag ({{date}})',
                sunday: 'Afgelopen zondag ({{date}})',
            },
            weekDay: {
                monday: 'Maandag {{date}}',
                tuesday: 'Dinsdag {{date}}',
                wednesday: 'Woensdag {{date}}',
                thursday: 'Donderdag {{date}}',
                friday: 'Vrijdag {{date}}',
                saturday: 'Zaterdag {{date}}',
                sunday: 'Zondag {{date}}',
            },
            changeDate: 'Datum aanpassen',
        },
        field: {
            id: { label: 'ID' },
            projects: { label: 'Projecten' },
            user: { label: 'Gebruiker' },
            projectsOrTickets: { label: 'Projecten of tickets' },
            tickets: { label: 'Tickets' },
            description: { label: 'Omschrijving' },
            date: { label: 'Datum' },
            startedAt: { label: 'Gestart' },
            endedAt: { label: 'Gestopt' },
            duration: { label: 'Tijdsduur' },
            projectTeam: { label: 'Projectteam' },
            userTeam: { label: 'Gebruikersteam' }
        },
    },
    ticket: {
        overview: {
            title: 'Tickets',
        },
        field: {
            number: {
                label: 'Ticket',
            },
            id: {
                label: 'ID'
            },
            title: {
                label: 'Titel'
            },
            assignedTo: {
                label: 'Toegewezen aan'
            },
            timeSpent: {
                label: 'Tijd gespendeerd'
            }
        }
    },
    tag: {
        overview: {
            addButton: 'Tag toevoegen'
        },
        create: {
            title: 'Tag toevoegen'
        },
        edit: {
            title: 'Tag aanpassen'
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Naam'
            },
        },
    },
    entity: {
         overview: {
            title: 'Entiteiten',
            addButton: 'Entiteit toevoegen',
        },
        create: {
            title: 'Entiteit toevoegen',
        },
        edit: {
            title: 'Entiteit bewerken',
        },
        field: {
             name: {
                 label: 'Naam'
             }
        }
    },
    company: {
        overview: {
            addButton: 'Bedrijf toevoegen'
        },
        create: {
            title: 'Bedrijf toevoegen'
        },
        edit: {
            title: 'Bewerk bedrijf'
        },
        field: {
            name: {
                label: 'Naam'
            },
            shortName: {
                label: 'Korte Naam'
            },
            mainContact: {
                label: 'Hoofdcontact'
            },
            responsible: {
                label: 'Verantwoordelijke'
            },
            qaEmail: {
                label: 'QA e-mail'
            },
            communicationRhythm: {
                label: 'Communicatieritme'
            },
            invoiceAgreement: {
                label: 'Facturateafspraken'
            }
        }
    },
    project: {
        create: {
            title: 'Project toevoegen'
        },
        edit: {
            title: 'Project bewerken'
        },
        overview: {
            addButton: 'Project toevoegen',
            title: 'Projecten'
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Naam'
            },
            team: {
                label: 'Team',
            },
            company: {
                label: 'Bedrijf'
            },
            quotationLink: {
                label: 'Offerte'
            },
            hasActiveBudget: {
                label: 'Heeft actief budget'
            },
            projectLead: {
                label: 'Projectlead'
            },
            teamMember: {
                label: 'Projectlid'
            }
        }
    },
    contact: {
        overview: {
            addButton: 'Contact toevoegen',
            title: 'Contacten'
        },
        create: {
            title: 'Contact toevoegen'
        },
        edit: {
            title: 'Contact bewerken'
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Naam'
            },
            company: {
                label: 'Bedrijf'
            },
            fullName: {
                label: 'Naam'
            },
            firstName: {
                label: 'Voornaam'
            },
            lastName: {
                label: 'Achternaam'
            }
        }
    },
    team: {
        overview: {
            addButton: 'Team toevoegen'
        },
        create: {
            title: 'Team toevoegen'
        },
        edit: {
            title: 'Team bewerken'
        },
        field: {
            id: {
                label: 'ID'
            },
            company: {
                label: 'Bedrijf'
            },
            name: {
                label: 'Naam'
            }
        }
    },
    history: {
        modal: {
            milestone: {
                title: 'Aanpassingen aan milestone'
            }
        }
    },
    phabricator: {
        linked: 'Gelinkt met Phabricator'
    },
    server: {
        overview: {
            addButton: 'Server toevoegen',
            title: 'Serveroverzicht'
        },
        create: {
            title: 'Server toevoegen'
        },
        edit: {
            title: 'Server aanpassen'
        },
        field: {
            id: {
                label: 'ID'
            },
            serverUrl: {
                label: 'Server URL'
            },
            company: {
                label: 'Bedrijf'
            },
            name: {
                label: 'Naam'
            },
            iaeVersion: {
                label: 'IAE versie'
            },
            branch: {
                label: 'Branch'
            },
            softwareVersion: {
                label: 'Software versie'
            },
            buildDate: {
                label: 'Builddatum',
            },
            lastRetrievedDate: {
                label: 'Laatst geüpdatet'
            },
            team: {
                label: 'Team'
            },
            commitId: {
                label: 'Commit Hash'
            },
            commitNumber: {
                label: 'Commit Number'
            },
            serverType: {
                label: 'Type',
                option: {
                    staging: 'Staging',
                    uat: 'UAT',
                    production: 'Productie'
                }
            },
            backups: {
                label: 'Backups',
                disabled: 'Uitgeschakeld',
                error: 'Geen backup gevonden',
                enabled: {
                    label: 'Backups',
                    option: {
                        enabled: 'Ingeschakeld',
                        both: 'Beide',
                        disabled: 'Uitgeschakeld'
                    }
                },
                type: {
                    label: 'Backup type',
                },
                date: {
                    label: 'Laatste backup gemaakt',
                },
                size: {
                    label: 'Laatste backup grootte (min. in bytes)',
                },
            },
            error: {
                label: 'Error'
            }
        }
    },
    thread: {
        field: {
            notes: {
                none: 'Geen notities',
                more: '({{count}} meer)',
            },
        },
    },
    note: {
        field: {
            contents: { label: 'Inhoud' },
        },
    },
    module: {
        create: {
            title: 'Module toevoegen'
        },
        edit: {
            title: 'Module bewerken'
        },
        field: {
            id: {
                label: 'Id',
            },
            name: {
                label: 'Naam'
            },
            team: {
                label: 'Team'
            },
            callSign: {
                label: 'Call Sign'
            },
            description: {
                label: 'Beschrijving & Relevant process'
            },
            origin: {
                label: 'Origine project'
            },
            logo: {
                label: 'Logo'
            }
        }
    },
     chapter: {
        overview: {
            addButton: 'Hoofdstuk'
        },
        edit: {
            title: 'Hoofdstuk bewerken'
        },
        create: {
            title: 'Hoofdstuk toevoegen'
        },
        field: {
            order: {
                label: 'Volgorde'
            },
            name: {
                label: 'Naam'
            }
        }
    },
    document: {
        edit: {
            title: 'Bewerk document'
        },
        field: {
            chapter: {
                label: 'Hoofddstuk'
            },
            order: {
                label: 'Volgorde'
            },
            name: {
                label: 'Naam'
            },
            responsible: {
                label: 'Verantwoordelijke persoon'
            },
            documentUrl: {
                label: 'Url'
            },
            lastChangedDate: {
              label: 'Laatste verandering'
            },
            lastRevisionDate: {
                label: 'Laaste revisiedatum'
            },
            type: {
                label: 'Documenttype',
                option: {
                    document: 'Document',
                    register: 'Register',
                    form: 'Form',
                    process: 'Proces'
                }
            }
        },
        create: {
            title: 'Document toevoegen'
        }
    },
    workingStatus: {
        working: 'Aan het werk aan {{subject}} sinds {{startedAt}}',
        notWorking: 'Niet aan het werk',
        unspecified: 'ongespecificeerd',
    },
    contract: {
        overview: {
            addButton: 'Contract toevoegen',
            title: 'Contracten'
        },
        create: {
            title: 'Contract toevoegen'
        },
        edit: {
            title: 'Contract bewerken',
            sections: {
                project: 'Project',
                info: 'Projectinfo',
                responsibilities: 'Verantwoordelijkheden'
            }
        },
        field: {
            entity: {
                label: 'Entiteit'
            },
            company: {
                label: 'Bedrijf'
            },
            title: {
                label: 'Titel'
            },
            reference: {
                label: 'Referentie'
            },
            quotation: {
                label: 'Offerte'
            },
            description: {
                label: 'Omschrijving'
            },
            quotationLink: {
                label: 'Link offerte'
            },
            signedPdf: {
                label: 'Getekende offerte'
            },
            customerContact: {
                label: 'Verantwoordelijke klant'
            },
            team: {
                label: 'Team'
            },
            projectLead: {
                label: 'Project Lead'
            },
            devLead: {
                label: 'Dev lead'
            },
            uxdLead: {
                label: 'UXD Lead'
            },
            status: {
                label: 'Status'
            },
            budgetType: {
                label: 'Budgettype'
            }
        },
        status: {
            'quotation draft': 'Offerte draft',
            'quotation sent': 'Offerte verstuurd',
            'quotation on hold': 'Offerte on hold',
            'quotation rejected': 'Offerte afgekeurd',
            'contract active': 'Contract actief',
            'contract finished': 'Contract af'
        }
    },
    budget: {
        create: {
            title: 'Budget toevoegen'
        },
        edit: {
            title: 'Budget bewerken'
        },
        field: {
            name: {
                label: 'Naam'
            },
            type: {
                label: 'Type'
            },
            description: {
                label: 'Beschrijving'
            },
            startDate: {
                label: 'Startdatum'
            },
            endDate: {
                label: 'Einddatum'
            },
            budgetMoney: {
                label: 'Budget'
            },
            budgetHours: {
                label: 'Urenbudget'
            },
            extraHoursArePaid: {
                label: 'Extra uren zijn betaald'
            },
            budgetHourlyRate: {
                label: 'Uurtarief voor extra uren'
            },
            budgetHourlyMaxHours: {
                label: 'Maximaal aantal extra uren'
            },
            project: {
                label: 'Project'
            },
            contract: {
                label: 'Contract'
            }
        }
    },
    budgetProject: {
        field: {
            budget: {
                label: 'Budget'
            }
        }
    }
};
